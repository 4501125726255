
import { defineComponent } from "vue";

export default defineComponent({
    name: "Header",
    props: ["open"],
    data () {
        return {
            msg: "Saluton, rsk!",
            user: {
                src: "https://rasikyou.ddns.me/user/index.php",
                content: " Agordoj"
            },
            logoutBtn: {
                src: "#",
                content: "Elsaluti"
            },
            show: false
        };
    },
    methods: {
        onToggleClick () {
            const php = document.getElementById("php");

            if (!this.open) {
                this.$emit('setOpen');
                this.$emit('setMinLeftPanel', false);
                this.$emit('setMt5', true);

                if (php?.style?.borderStyle) {
                    php.style.borderStyle = 'none';
                }
            } else {
                this.$emit('setOpen');
                this.$emit('setMinLeftPanel', true);
                this.$emit('setMt5', false);

                if (php?.style?.borderStyle) {
                    php.style.borderStyle = 'solid';
                }
            }
        },
        onConfigClick () {
            if (!this.show) {
                this.show = true;
            } else {
                this.show = false;
            }
        },
        logout: () => {
            const hostname = "https://rsk-api.servehttp.com";
            const url = hostname + "/User/logout.php";
            fetch(url, {
                method: "GET",
                mode: "cors",
                credentials: "include"
            }).then((response) => {
                return response.json();
            }).then(() => {
                window.location.replace("/user/leaving.php");
            });
        }
    }
});
