import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
// import Home from '../views/Home.vue';
// import Update from '../views/Update.vue';

const routes: Array<RouteRecordRaw> = [
    {
        path: '/dashboard',
        name: 'Dashboard',
        // component: Dashboard
        component: () => import(/* webpackChunkName: "about" */ '../views/Dashboard.vue')
    },
    {
        path: '/update',
        name: 'Update',
        // component: Update
        component: () => import(/* webpackChunkName: "about" */ '../views/Update.vue')
    },
    {
        path: '/table/:type/:view',
        name: 'Table',
        // component: Update
        component: () => import(/* webpackChunkName: "about" */ '../views/Datatable.vue')
    },
    {
        path: '/bookstack',
        name: 'Bookstack',
        component: () => import(/* webpackChunkName: "about" */ '../views/Bookstack.vue')
    },
    {
        path: '/',
        name: 'Home',
        // component: process.env.NODE_ENV === 'production' ? () => import(/* webpackChunkName: "about" */ '../views/Dashboard.vue') : Home
        component: () => import(/* webpackChunkName: "about" */ '../views/Home.vue')
    }
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
});

export default router;
