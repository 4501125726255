<template>
    <header class="header border">
        <div class="header-menu">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-1">
                        <a
                            id="app.menu.toggle"
                            class="menutoggle pull-left"
                            v-on:click="onToggleClick"
                        >
                            <i class="fas fa-tasks">&nbsp;</i>
                        </a>
                    </div>
                    <!-- .col-1 -->
                    <div class="col-6"></div>
                    <!-- .col-6 -->
                    <div class="col-5">
                        <div class="user-area float-right">
                            <span
                                class="mr-3"
                                style="position:relative;top:10px"
                                >{{msg}}</span
                            >
                            <a
                                href="#"
                                class="app.menu.config dropdown-toggle"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                                v-on:click="onConfigClick"
                            >
                                <img
                                    class="user-avatar rounded-circle"
                                    src="../assets/admin.png"
                                    alt="User Avatar"
                                />
                            </a>
                            <div
                                class="app.menu.usermenu user-menu dropdown-menu border rounded"
                                :class="{show_config: show}"
                            >
                                <a
                                    class="nav-link"
                                    v-bind:href="user.src"
                                    ><i class="fas fa-cog">&nbsp;</i>{{user.content}}</a
                                >
                                <a class="app.menu.logout nav-link"
                                    v-bind:href="logoutBtn.src"
                                    v-on:click="logout"
                                    ><i class="fas fa-power-off">&nbsp;</i>
                                    {{logoutBtn.content}}</a
                                >
                            </div>
                        </div>
                    </div>
                    <!-- .col-5 -->
                </div>
                <!-- class="row" -->
            </div>
            <!-- class="container-fluid" -->
        </div>
        <!-- class="header-menu" -->
    </header>
    <!-- /header -->
</template>
<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
    name: "Header",
    props: ["open"],
    data () {
        return {
            msg: "Saluton, rsk!",
            user: {
                src: "https://rasikyou.ddns.me/user/index.php",
                content: " Agordoj"
            },
            logoutBtn: {
                src: "#",
                content: "Elsaluti"
            },
            show: false
        };
    },
    methods: {
        onToggleClick () {
            const php = document.getElementById("php");

            if (!this.open) {
                this.$emit('setOpen');
                this.$emit('setMinLeftPanel', false);
                this.$emit('setMt5', true);

                if (php?.style?.borderStyle) {
                    php.style.borderStyle = 'none';
                }
            } else {
                this.$emit('setOpen');
                this.$emit('setMinLeftPanel', true);
                this.$emit('setMt5', false);

                if (php?.style?.borderStyle) {
                    php.style.borderStyle = 'solid';
                }
            }
        },
        onConfigClick () {
            if (!this.show) {
                this.show = true;
            } else {
                this.show = false;
            }
        },
        logout: () => {
            const hostname = "https://rsk-api.servehttp.com";
            const url = hostname + "/User/logout.php";
            fetch(url, {
                method: "GET",
                mode: "cors",
                credentials: "include"
            }).then((response) => {
                return response.json();
            }).then(() => {
                window.location.replace("/user/leaving.php");
            });
        }
    }
});
</script>
