<template>
  <!-- Left Panel -->
  <Navigator
    v-show="$route.path!==loginPage && $route.path!==loginFile"
    :minLeftPanel="minLeftPanel"
    :mt5="mt5"
    :open="open"
  />
  <!-- Left Panel -->
  <!-- Right Panel -->
  <div class="right-panel" :class="{open: open}" v-if="$route.path!==loginPage && $route.path!==loginFile">
    <!-- Header-->
    <Header
      v-show="$route.path!=='/'"
      v-on:setMinLeftPanel="setMinLeftPanel"
      v-on:setMt5="setMt5"
      v-on:setOpen="setOpen"
    />
    <!-- Header-->

    <div class="breadcrumbs" >
      <div class="container-fluid">
        <div class="row">
          <div class="col-sm-4" style="z-index:2">
            <div class="page-header float-left">
              <div class="page-title"><h1>Dashboard</h1></div>
            </div>
          </div>
          <div class="col-sm-8" style="z-index:2">
            <div class="page-header float-right">
              <div class="page-title">
                <ol class="breadcrumb text-right">
                  <li><a href="#">{{$route.name}}</a></li>
                  <!--li class="active">index.php</li-->
                </ol>
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr>
      <router-view/>
    </div>
  </div>
  <router-view v-if="$route.path===loginPage || $route.path===loginFile"/>
</template>
<style>
@import "https://stackpath.bootstrapcdn.com/bootstrap/4.4.1/css/bootstrap.min.css";
@import "https://use.fontawesome.com/releases/v5.8.1/css/solid.css";
@import "https://use.fontawesome.com/releases/v5.8.1/css/fontawesome.css";
@import "https://rasikyou.ddns.me/assets/css/style.css";
@import "https://rasikyou.ddns.me/assets/css/custom.css";
</style>
<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /*text-align: center;*/
  color: #2c3e50;
  display: table;
  width: 100%;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
<script lang="ts">
import { defineComponent } from 'vue';
import Navigator from '@/components/Navigator.vue';
import Header from '@/components/Header.vue';

export default defineComponent({
    name: 'App',
    data () {
        return {
            minLeftPanel: false,
            mt5: true,
            open: false,
            loginPage: '/',
            loginFile: '/index.html'
        };
    },
    components: {
        Navigator,
        Header
    },
    methods: {
        setMinLeftPanel (_open: boolean) {
            this.minLeftPanel = _open;
        },
        setMt5 (add: boolean) {
            this.mt5 = add;
        },
        setOpen () {
            this.open = !this.open;
            // console.log(this.open);
        }
    }
});
</script>
