<template>
    <aside id="left-panel" class="left-panel" :class="{min_left_panel: minLeftPanel}">
        <nav class="navbar navbar-expand-sm navbar-default">
            <div id="header-bar-bg" class="navbar-header">
                <button
                    class="navbar-toggler"
                    type="button"
                    data-toggle="collapse"
                    data-target="#main-menu"
                    aria-controls="main-menu"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                    v-on:click="onClick"
                >
                    <i class="fas fa-bars">&nbsp;</i>
                </button>
                <router-link to="/dashboard/">
                    <div class="navbar-brand" :class="{open: open}">
                        <img src="../assets/logo.png" alt="Logo" />
                    </div>
                </router-link>
            </div>
            <div    id="main-menu"
                    class="main-menu collapse navbar-collapse"
                    :class="{
                        'mt-5': mt5,
                        'show_mainmenu': mainManu,
                        'hide_mainmenu': !mainManu
                    }"
                >
                <ul class="nav navbar-nav" style="z-index:100">
                    <li>
                        <!--a href="https://rasikyou.ddns.me/dashboard/" target="_self">
                            <i class="menu-icon fas fa-tachometer-alt">&nbsp;</i
                            >Panelo</a
                        -->
                        <router-link to="/dashboard/">
                            <i class="menu-icon fas fa-tachometer-alt">&nbsp;</i>
                            <span class="nav-text" :class="{open: open}">Panelo</span>
                        </router-link>
                    </li>
                    <li><h3 class="menu-title" :class="{open: open}">buĝeto</h3></li>
                    <!-- /.menu-title -->
                    <li>
                        <router-link to="/update/">
                            <i class="menu-icon fas fa-edit">&nbsp;</i>
                            <span class="nav-text" :class="{open: open}">Ĝisdatigo</span>
                        </router-link>
                    </li>
                    <li class="menu-item-has-children dropdown">
                        <a
                            href="#"
                            class="dropdown-toggle"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                        >
                            <i class="menu-icon fas fa-calendar-alt">&nbsp;</i>
                            <span class="nav-text" :class="{open: open}">Tablo</span>
                        </a>
                        <ul class="sub-menu children dropdown-menu">
                            <!-- begin of 1 -->
                            <li v-for="item in table" :key="item.content">
                                <!--a
                                    v-bind:href="item.src"
                                    v-bind:target="item.target"
                                >
                                    <i v-bind:class="item.icon">&nbsp;</i >
                                    {{item.content}}
                                </a -->
                                <router-link v-bind:to="item.src">
                                    <i v-bind:class="item.icon" style="margin-top:-3px;">&nbsp;</i>
                                    {{item.content}}
                                </router-link>
                            </li>
                            <!-- end of 1 -->
                        </ul>
                    </li>
                    <li id="php"
                        style="border-color:yellow ;border-style: solid; border-radius:5px; margin-left: -10px; padding-left: 10px;">
                        <a
                            href="/chart/week"
                            target="_self"
                            ><i class="menu-icon fas fa-folder">&nbsp;</i
                            >
                            <span class="nav-text" :class="{open: open}">PHP</span>
                        </a>
                    </li>
                    <li>
                        <router-link to="/bookstack/">
                            <i class="menu-icon fas fa-swatchbook">&nbsp;</i>
                            <span class="nav-text" :class="{open: open}">Librovendejo</span>
                        </router-link>
                    </li>
                    <!--li class="menu-item-has-children dropdown">
                        <a
                            href="#"
                            class="dropdown-toggle"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                            ><i class="menu-icon fas fa-chart-area">&nbsp;</i
                            >Skemo
                        </a>
                        <ul class="sub-menu children dropdown-menu"-->
                            <!-- begin of 2 -->
                            <!--li v-for="item in chart" :key="item.content">
                                <a
                                    v-bind:href="item.src"
                                    v-bind:target="item.target"
                                >
                                    <i v-bind:class="item.icon">&nbsp;</i >
                                    {{item.content}}
                                </a >
                            </li-->
                            <!-- end of 2 -->
                        <!--/ul>
                    </li-->
                    <!-- begin of 3 -->
                    <li v-for="item in items" :key="item.content">
                        <a
                            v-bind:href="item.src"
                            v-bind:target="item.target"
                        >
                            <i v-bind:class="item.icon">&nbsp;</i >
                            <span class="nav-text" :class="{open: open}">{{item.content}}</span>
                        </a >
                    </li>
                    <!-- end of 3 -->
                    <li>
                        <a class="app.menu.logout" href="#" v-on:click="logout"
                            ><i class="menu-icon fas fa-sign-out-alt">&nbsp;</i
                            >
                            <span class="nav-text" :class="{open: open}">Elsaluti</span>
                        </a>
                    </li>
                </ul>
            </div>
            <!-- /.navbar-collapse -->
        </nav>
    </aside>
    <!-- /#left-panel -->
</template>
<style scoped>
.dropdown-toggle::after {
    margin-top: 15px !important;
    margin-right: 10px !important;
}
</style>
<script lang="ts">
/* menu toggle */
import { defineComponent } from "vue";
export default defineComponent({
    props: ["minLeftPanel", "mt5", "open"],
    name: "Navigator",
    data () {
        return {
            mainManu: false,
            table: [
                /*
                { content: "Detalo en Monato", src: "https://rasikyou.ddns.me/tables/month/all", icon: "menu-icon fas fa-calendar-alt", target: "_self" },
                { content: "Detalo en Tago", src: "https://rasikyou.ddns.me/tables/days/json", icon: "menu-icon fas fa-calendar-day", target: "_self" },
                { content: "Detalo en Semajno", src: "https://rasikyou.ddns.me/tables/week/all", icon: "menu-icon fas fa-calendar-week", target: "_self" }
                */
                { content: "Detalo en Monato", src: "/table/month/all", icon: "menu-icon fas fa-calendar-alt", target: "_self" },
                { content: "Detalo en Tago", src: "/table/days/json", icon: "menu-icon fas fa-calendar-day", target: "_self" },
                { content: "Detalo en Semajno", src: "/table/week/all", icon: "menu-icon fas fa-calendar-week", target: "_self" }
            ],
            /*
            chart: [
                { content: "Semajno", src: "https://rasikyou.ddns.me/chart/week", icon: "menu-icon fas fa-chart-bar", target: "_self" },
                { content: "Monato", src: "https://rasikyou.ddns.me/chart/month", icon: "menu-icon fas fa-chart-pie", target: "_self" },
                { content: "Jaro", src: "https://rasikyou.ddns.me/chart/year", icon: "menu-icon fas fa-align-left", target: "_self" },
                { content: "Semajnoj en Monato", src: "https://rasikyou.ddns.me/chart/weeks", icon: "menu-icon fas fa-chart-bar", target: "_self" },
                { content: "Monatoj en Jaro", src: "https://rasikyou.ddns.me/chart/d3js", icon: "menu-icon fas fa-chart-bar", target: "_self" }
            ],
            */
            items: [
                { content: "Kalendaro", src: "https://rasikyou.ddns.me/calendar/", icon: "menu-icon fas fa-calendar", target: "_blank" },
                { content: "Reader", src: "https://rasikyou.ddns.me/reader/", icon: "menu-icon fas fa-book", target: "_blank" },
                { content: "Handsontable", src: "https://rasikyou.ddns.me/handsontable/", icon: "menu-icon fas fa-table", target: "_blank" }
            ]
        };
    },
    methods: {
        onClick () {
            this.mainManu = !this.mainManu;
        },
        logout: () => {
            const hostname = "https://rsk-api.servehttp.com";
            const url = hostname + "/User/logout.php";
            fetch(url, {
                method: "GET",
                mode: "cors",
                credentials: "include"
            }).then((response) => {
                // console.log(response);
                return response.json();
            }).then(() => {
                window.location.replace("/user/leaving.php");
            });
        }
    }
    /*
    mounted () {
        // const els = Array.from(document.getElementsByClassName('dropdown-toggle') as HTMLCollectionOf<HTMLElement>);
        console.log(els);
        for (let i = 0; i < els.length; i++) {
            const el = els[i];
            if (el?.style?.borderTop) {
                el.style.borderTop = '.3em solid';
            }
        }
    }
    */
});
</script>
