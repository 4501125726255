
import { defineComponent } from 'vue';
import Navigator from '@/components/Navigator.vue';
import Header from '@/components/Header.vue';

export default defineComponent({
    name: 'App',
    data () {
        return {
            minLeftPanel: false,
            mt5: true,
            open: false,
            loginPage: '/',
            loginFile: '/index.html'
        };
    },
    components: {
        Navigator,
        Header
    },
    methods: {
        setMinLeftPanel (_open: boolean) {
            this.minLeftPanel = _open;
        },
        setMt5 (add: boolean) {
            this.mt5 = add;
        },
        setOpen () {
            this.open = !this.open;
            // console.log(this.open);
        }
    }
});
