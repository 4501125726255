
/* menu toggle */
import { defineComponent } from "vue";
export default defineComponent({
    props: ["minLeftPanel", "mt5", "open"],
    name: "Navigator",
    data () {
        return {
            mainManu: false,
            table: [
                /*
                { content: "Detalo en Monato", src: "https://rasikyou.ddns.me/tables/month/all", icon: "menu-icon fas fa-calendar-alt", target: "_self" },
                { content: "Detalo en Tago", src: "https://rasikyou.ddns.me/tables/days/json", icon: "menu-icon fas fa-calendar-day", target: "_self" },
                { content: "Detalo en Semajno", src: "https://rasikyou.ddns.me/tables/week/all", icon: "menu-icon fas fa-calendar-week", target: "_self" }
                */
                { content: "Detalo en Monato", src: "/table/month/all", icon: "menu-icon fas fa-calendar-alt", target: "_self" },
                { content: "Detalo en Tago", src: "/table/days/json", icon: "menu-icon fas fa-calendar-day", target: "_self" },
                { content: "Detalo en Semajno", src: "/table/week/all", icon: "menu-icon fas fa-calendar-week", target: "_self" }
            ],
            /*
            chart: [
                { content: "Semajno", src: "https://rasikyou.ddns.me/chart/week", icon: "menu-icon fas fa-chart-bar", target: "_self" },
                { content: "Monato", src: "https://rasikyou.ddns.me/chart/month", icon: "menu-icon fas fa-chart-pie", target: "_self" },
                { content: "Jaro", src: "https://rasikyou.ddns.me/chart/year", icon: "menu-icon fas fa-align-left", target: "_self" },
                { content: "Semajnoj en Monato", src: "https://rasikyou.ddns.me/chart/weeks", icon: "menu-icon fas fa-chart-bar", target: "_self" },
                { content: "Monatoj en Jaro", src: "https://rasikyou.ddns.me/chart/d3js", icon: "menu-icon fas fa-chart-bar", target: "_self" }
            ],
            */
            items: [
                { content: "Kalendaro", src: "https://rasikyou.ddns.me/calendar/", icon: "menu-icon fas fa-calendar", target: "_blank" },
                { content: "Reader", src: "https://rasikyou.ddns.me/reader/", icon: "menu-icon fas fa-book", target: "_blank" },
                { content: "Handsontable", src: "https://rasikyou.ddns.me/handsontable/", icon: "menu-icon fas fa-table", target: "_blank" }
            ]
        };
    },
    methods: {
        onClick () {
            this.mainManu = !this.mainManu;
        },
        logout: () => {
            const hostname = "https://rsk-api.servehttp.com";
            const url = hostname + "/User/logout.php";
            fetch(url, {
                method: "GET",
                mode: "cors",
                credentials: "include"
            }).then((response) => {
                // console.log(response);
                return response.json();
            }).then(() => {
                window.location.replace("/user/leaving.php");
            });
        }
    }
    /*
    mounted () {
        // const els = Array.from(document.getElementsByClassName('dropdown-toggle') as HTMLCollectionOf<HTMLElement>);
        console.log(els);
        for (let i = 0; i < els.length; i++) {
            const el = els[i];
            if (el?.style?.borderTop) {
                el.style.borderTop = '.3em solid';
            }
        }
    }
    */
});
